






import Vue from "vue";
//import HelloWorld from "./components/HelloWorld.vue";
export default Vue.extend({
  name: "App",

  components: {
    //HelloWorld
  },

  metaInfo: {
    title: "Keep Running EC"
  },

  data: () => ({
    //
  }),
  mounted() {
    console.log(process.env.NODE_ENV);
    
    if (document.getElementById("gtm-script-tag") === null && process.env.VUE_APP_STAGE === "prod") {
      this.initializeGTM();
    }

    if (document.getElementById("gtm-noscript-tag") === null && process.env.VUE_APP_STAGE === "prod") {
      this.initializeNoScriptTagGTM();
    }
  },
  methods: {
    initializeGTM() {
      // Initialize Google Tag Manager
      const gtmScript = document.createElement("script");
      gtmScript.id = "gtm-script-tag";

      gtmScript.onload = () => {
        console.log("GTM script loaded successfully");
      };

      gtmScript.innerHTML = `
       (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-52BNWK2C');
      `;
      document.head.appendChild(gtmScript);
    },
    initializeNoScriptTagGTM() {
      // Initialize Google Tag Manager (noscript)
      const gtmNoScript = document.createElement("noscript");
      gtmNoScript.id = "gtm-noscript-tag";
      
      const gtmIframe = document.createElement("iframe");
      gtmIframe.id = "gtm-iframe-tag";
      gtmIframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-52BNWK2C";
      gtmIframe.height = "0";
      gtmIframe.width = "0";
      gtmIframe.style.display = "none";
      gtmIframe.style.visibility = "hidden";

      gtmNoScript.appendChild(gtmIframe);
      document.body.insertBefore(gtmNoScript, document.body.firstChild);
    }
  }
});
