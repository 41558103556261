import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

import Web from "../views/web/Main.vue";
import Admin from "../views/admin/Main.vue";
import Inicio from "../views/web/Inicio.vue";
import Proximo from "../views/web/Proximo.vue";
import Organizado from "../views/web/Organizado.vue";
import Otros from "../views/web/Otros.vue";
//import Cart from "../views/web/Cart.vue";
import Cart from "../views/web/cart/Cart.vue";

import CreateEvent from "../views/admin/CreateEvent.vue";
import AuthStore from "@/store/modules/AuthStore";

import CarreraDetalle from "../views/web/CarreraDetalle.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  /*
  {
    path: "/",
    name: "Home",
    component: Home
  },
  */
  //admin
  {
    path: "/admin",
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (AuthStore.getLoggedIn && AuthStore.getRole != "0") {
        //next("/expired");
        next();
      } else {
        next("/");
      }
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home
      },
      {
        path: "/admin/banners",
        name: "Banners",
        component: () =>
          import(/* webpackChunkName: "banners" */ "../views/admin/Banners.vue")
      },
      {
        path: "/admin/addevent",
        name: "Crearcarrera",
        component: CreateEvent
      },
      {
        path: "cuenta-bancaria",
        name: "CuentaBancaria",
        component: () =>
          import(
            /* webpackChunkName: "cuenta-bancaria" */ "../views/admin/CuentaBancaria.vue"
          )
      },
      {
        path: "home-video",
        name: "HomeVideo",
        component: () =>
          import(
            /* webpackChunkName: "home-video" */ "../views/admin/configuracion/HomeVideo.vue"
          )
      },
      {
        path: "evento/talla",
        name: "Talla",
        component: () =>
          import(
            /* webpackChunkName: "evento-talla" */ "../views/admin/evento/Talla.vue"
          )
      },
      {
        path: "evento/distancia",
        name: "Distancia",
        component: () =>
          import(
            /* webpackChunkName: "evento-distancia" */ "../views/admin/evento/Distancia.vue"
          )
      },
      {
        path: "evento/carrera",
        name: "Carrera",
        component: () =>
          import(
            /* webpackChunkName: "carrera" */ "../views/admin/evento/Carrera.vue"
          )
      },
      {
        path: "evento/carrera/editor/:id",
        name: "CarreraEditor",
        component: () =>
          import(
            /* webpackChunkName: "carrera-editor" */ "../views/admin/evento/carrera/CarreraEditor.vue"
          )
      },
      {
        path: "persona",
        name: "Persona",
        component: () =>
          import(/* webpackChunkName: "persona" */ "../views/admin/Persona.vue")
      },
      {
        path: "inventario/general/articulo/:articuloID",
        name: "movimiento-articulo-by-id",
        component: () =>
          import(
            /* webpackChunkName: "inventario-articulo-by-id" */ "../views/admin/inventario/articulo/ArticuloInventario.vue"
          )
      },
      {
        path: "transacciones",
        name: "Transacciones",
        component: () =>
          import(
            /* webpackChunkName: "inventario" */ "../views/admin/transacciones/Transacciones.vue"
          )
      },
      {
        path: "paymentez/transactions",
        name: "paymentezTransactions",
        component: () =>
          import(
            /* webpackChunkName: "inventario" */ "../views/admin/transacciones/PaymentezTransactions.vue"
          )
      },
      {
        path: "encuestas-eventos",
        name: "encuestasEventos",
        component: () =>
          import(
            /* webpackChunkName: "encuestas-eventos" */ "../views/admin/encuestas/Encuestas.vue"
          )
      },
      {
        path: "*",
        name: "NotFound",
        component: () =>
          import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue")
      }
    ]
  },
  //web
  {
    path: "/",
    component: Web,
    children: [
      {
        path: "/",
        name: "Home",
        component: Inicio
      },
      {
        path: "inicio",
        name: "Inicio",
        component: Inicio
      },
      {
        path: "account/update-password",
        name: "UpdatePassword",
        component: () =>
          import(
            /* webpackChunkName: "cuenta-change-password" */ "../views/web/account/UpdatePassword.vue"
          )
      },
      {
        path: "account/pedidos",
        name: "Pedidos",
        component: () =>
          import(
            /* webpackChunkName: "cuenta-pedidos-pagados" */ "../views/web/cliente/PedidosPagados.vue"
          )
      },
      {
        path: "account/registros",
        name: "Registros",
        component: () =>
          import(
            /* webpackChunkName: "cuenta-registros-pagados" */ "../views/web/cliente/RegistrosPagados.vue"
          ),
        beforeEnter: (to, from, next) => {
          if (AuthStore.getLoggedIn) {
            next();
          } else {
            next("/login");
          }
        }
      },
      {
        path: "pago-exitoso",
        name: "PagoExitoso",
        component: () =>
          import(
            /* webpackChunkName: "cuenta-registros-pagados" */ "../views/web/pago/PagoCompletado.vue"
          ),
        beforeEnter: (to, from, next) => {
          if (AuthStore.getLoggedIn) {
            next();
          } else {
            next("/login");
          }
        }
      },
      {
        path: "pago-fallido",
        name: "PagoFallido",
        component: () =>
          import(
            /* webpackChunkName: "cuenta-registros-pagados" */ "../views/web/pago/PagoFallido.vue"
          ),
        beforeEnter: (to, from, next) => {
          if (AuthStore.getLoggedIn) {
            next();
          } else {
            next("/login");
          }
        }
      },
      {
        path: "account/mis-citas",
        name: "MisCitas",
        component: () =>
          import(
            /* webpackChunkName: "cuenta-registros-pagados" */ "../views/web/cliente/MisCitas.vue"
          )
      },

      {
        path: "account/registros/citas/:eventoID",
        name: "RegistrosCitas",
        component: () =>
          import(
            /* webpackChunkName: "cuenta-registros-pagados" */ "../views/web/cliente/AgendarCita.vue"
          )
      },

      {
        path: "proximas",
        name: "Proximas",
        component: Proximo
      },
      {
        path: "proximas/:id",
        name: "Detalle",
        component: CarreraDetalle
      },
      {
        path: "organizadas",
        name: "Organizado",
        component: Organizado
      },
      {
        path: "organizadas/:id",
        name: "organizada-detalle",
        component: () =>
          import(
            /* webpackChunkName: "organizada-detalle" */ "../views/web/CarreraOrganizadaDetalle.vue"
          )
      },
      {
        path: "otros",
        name: "Otros",
        component: Otros
      },
      {
        path: "contacto",
        name: "Contacto",
        component: () =>
          import(/* webpackChunkName: "contacto" */ "../views/web/Contacto.vue")
      },
      {
        path: "registro",
        name: "Registro",
        component: () =>
          import(/* webpackChunkName: "registro" */ "../views/SignUp.vue")
      },
      {
        path: "login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "registro" */ "../views/Login.vue")
      },
      {
        path: "cart",
        name: "Cart",
        component: Cart,
        meta: { hideFooterWeb: true },
        beforeEnter: (to, from, next) => {
          if (AuthStore.getLoggedIn) {
            next();
          } else {
            next("/login");
          }
        }
      },
      {
        path: "proceso-pago",
        name: "ProcesoPago",
        component: () =>
          import(
            /* webpackChunkName: "registro" */ "../views/web/pago/ProcesoPago.vue"
          )
      },
      {
        path: "/proceso-pago/subir-papeleta",
        name: "SubirPapeleta",
        component: () =>
          import(
            /* webpackChunkName: "registro" */ "../views/web/pago/SubirPapeleta.vue"
          ),
        beforeEnter: (to, from, next) => {
          if (AuthStore.getLoggedIn) {
            next();
          } else {
            next("/login");
          }
        }
      },
      {
        path: "cuestionario",
        name: "cuestionario",
        component: () =>
          import(
            /* webpackChunkName: "web-cuestionario" */ "../views/web/salud/Cuestionario.vue"
          )
      },
      {
        path: "expired",
        name: "expired",
        component: () =>
          import(/* webpackChunkName: "web-expired" */ "../views/Expired.vue")
      },
      {
        path: "*",
        name: "NotFound",
        component: () =>
          import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue")
      }
    ]
  }
];

const routesMaintenance: Array<RouteConfig> = [
  {
    path: "*",
    name: "Maintenance",
    component: () =>
      import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes:
    process.env.VUE_APP_MAINTENANCE_MODE === "true" ? routesMaintenance : routes
});

export default router;
