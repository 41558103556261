import httpClient from "./http-client";

const ENDPOINT = "/evento/carrera";

export interface CarreraCreateDto {
  id?: number;
  nombre: string;
  fechaInicioEvento: string | Date;
  fechaFinEvento: string | Date;
  //horaInicioEvento: number;
  //horaFinEvento: number;

  fechaInicioInscripcion: string | Date;
  fechaFinInscripcion: string | Date;
  //horaInicioInscripcion: number;
  //horaFinInscripcion: number;

  ciudad: string;
  lugar: string;

  estado: string;
  tipoParticipante: string;
  modalidad: string;

  hashtag: string;
  cupos: number;
  //nuevo
  estadoPersonalizacion: string;
  direccion: string;
  linkUbicacion: string;
  plataformaPagoId?: number;
  plataformaCarreras?: any[];
}

export interface GetResultadoCarrera {
  id: number;
}

interface MetricaDto {
  nombre: string;
  total: number;
}
export interface ReporteCarreraDto {
  tallas: MetricaDto[];
  distancias: MetricaDto[];
  tiposPago: MetricaDto[];
  generos: MetricaDto[];
  edades: MetricaDto[];
}

class CarreraService {
  getAll(): Promise<CarreraCreateDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getCarrera(idCarrera: string): Promise<CarreraCreateDto> {
    return httpClient.get(`${ENDPOINT}/${idCarrera}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getReporte(idCarrera: string): Promise<ReporteCarreraDto> {
    return httpClient.get(`${ENDPOINT}/reporte/${idCarrera}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  save(createDto: CarreraCreateDto) {
    return httpClient.post(ENDPOINT, createDto);
  }

  // nuevos endpoints
  getResultadosCarrera(
    idCarrera: string | number,
    params: string
  ): Promise<GetResultadoCarrera[]> {
    return httpClient
      .get(`${ENDPOINT}/resultado/${idCarrera}?${params}`)
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }

  getResultadosCarreraAdmin(
    idCarrera: string | number,
    params: string
  ): Promise<GetResultadoCarrera[]> {
    return httpClient
      .get(`${ENDPOINT}/resultado/admin/${idCarrera}?${params}`)
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }

  updateResultadoCarreraAdmin(
    idCarrera: number | string,
    idResultado: number | string,
    createDto: any
  ) {
    return httpClient.put(
      `${ENDPOINT}/resultado/admin/${idCarrera}/${idResultado}`,
      createDto
    );
  }

  deleteResultadosAdmin(idCarrera: string | number) {
    return httpClient.delete(`${ENDPOINT}/resultado/admin/${idCarrera}`);
  }

  deleteResultadoAdmin(
    idCarrera: string | number,
    idResultado: string | number
  ) {
    return httpClient.delete(
      `${ENDPOINT}/resultado/admin/${idCarrera}/${idResultado}`
    );
  }

  saveResultadosCarrera(createDto: FormData) {
    return httpClient.post(
      `${ENDPOINT}/resultado/cronometraje-from-excel`,
      createDto
    );
  }

  saveRegistroFromExcel(createDto: FormData) {
    return httpClient.post(`${ENDPOINT}/registro-from-excel`, createDto);
  }

  getCarrerasOrganizadas(): Promise<CarreraCreateDto[]> {
    return httpClient.get(`${ENDPOINT}/organizadas`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getCarreraOrganizada(idCarrera: number | string) {
    // me llega como parametro
    return httpClient
      .get(`${ENDPOINT}/organizadas/${idCarrera}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  getProximasCarreras(): Promise<CarreraCreateDto[]> {
    return httpClient.get(`${ENDPOINT}/proximas`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getProximaCarrera(idCarrera: string) {
    // me llega como parametro
    return httpClient
      .get(`${ENDPOINT}/proximas/${idCarrera}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  contarMisRegistros(idCarrera: any): Promise<number> {
    return httpClient
      .get(`${ENDPOINT}/persona/mis-registros/contar/${idCarrera}`)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        return -1;
      });
  }

  getMisRegistros(): Promise<any[]> {
    return httpClient
      .get(`${ENDPOINT}/persona/mis-registros`)
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
}
export default new CarreraService();
